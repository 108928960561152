export const SPEED_STEP = 1;
export const SPEED_BASE = 2;
export const SPEED_MULTIPLICATOR_STEP = 10;

export const SPAWN_INTERVAL_BASE = 1000;
export const SPAWN_INTERVAL_MIN = 200;
export const SPAWN_INTERVAL_STEP = 50;

export const TRAP_SKIN = [
    {value : 1, class : "balloon"},
    {value : 1, class : "seagull"},
]

export const COIN_SKIN = [
    {value : 2, class : "coin"},
    {value : 5, class : "treasure"},
]
