import React, {useRef, useState} from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate1" className={"strate bottom"}>
        <h2>Vous avez des questions<br/>ou besoin de plus d'informations ?</h2>
        <h4>Le Service Consommateurs de TLC <br className={"d-lg-none"}/>Marketing reste à votre disposition :</h4>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0974590344"}>09 74 59 03 44</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, tarif en <br className={"d-lg-none"}/>vigueur selon opérateur.<br className={"d-lg-block d-none"}/> Ligne active du lundi au <br className={"d-lg-none"}/>vendredi, de 9h30 à 17h30, hors jours fériés)
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}>
                <a href={"mailto:lafourneedoree-vacances@tlcrewards.com?subject=Opération%20«%20Le%20Goût%20des%20Vacances%20»%20de%20La%20Fournée%20Dorée%20/%20Vos%20nom%20et%20prénom"}>lafourneedoree-vacances@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération "Le Goût des Vacances" de La <br className={"d-lg-none"}/>Fournée Dorée /<br className={"d-lg-block d-none"}/> Vos nom et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact;
