import React from 'react';

const MentionsLegales = () => {
  return (
    <>
      <div className={"strate first header_global1 last"} id={"mlStrate1"}>
        <h1>Informations légales</h1>
        <div id={"ml_wrapper"}>
          <div className="block">
            <h4>Ce site est édité par :</h4>

            <p>
              TLC Marketing<br/>
              92 avenue de Wagram – 75017 Paris<br/>
              N° SIREN : 491 414 306<br/>
              N° SIRET : 491 414 306 00036<br/>
              APE : 7311Z<br/>
              RCS : Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              Numéro de TVA intracommunautaire : FR 70 491 414 306<br/>
              Adresse e-mail : <br className={"d-lg-none"}/>
              <a href="mailto:bonjour@tlcmarketing.com?subject=Opération%20«%20Le%20Goût%20des%20Vacances%20»%20de%20La%20Fournée%20Dorée%20/%20Vos%20nom%20et%20prénom" className={"bold"}>bonjour@tlcmarketing.com</a>
            </p>
          </div>
          <div className="block">
            <h4>Pour le compte de :</h4>
            <p>
              La Fournée Dorée<br/>
              ZA Sud des Achards – 6 rue de l’Océan  – CS 60014 – 85150 Les Achards<br/>
              N° SIREN : 817 390 941<br/>
              N° SIRET : 817 390 941 00018<br/>
              APE : 1071A<br/>
              RCS : La Roche-Sur-Yon B 817 390 941<br/>
              SARL unipersonnelle au capital social de : 500 000€<br/>
              Numéro de TVA intracommunautaire : FR 31 817 390 941
            </p>
          </div>
          <div className="block">
            <h4>Ce site est hébérgé par la société :</h4>
            <p>
              Cloud Media<br/>
              6 rue du Général Clergerie – 75116 PARIS<br/>
              N° SIREN : 752 470 971<br/>
              N° SIRET : 752 470 971 00025<br/>
              APE : 6201Z<br/>
              RCS : Paris B 752 470 971<br/>
              SAS au capital social de : 8 000€<br/>
              Numéro de TVA intracommunautaire : FR 19 752 470 971
            </p>
          </div>

          <div className={"smallBlock"}>
            La conception, le design et le développement du site ont été effectués par les agences suivantes : TLC Marketing France et Cloud Media.
          </div>

        </div>
      </div>
    </>

  );
};

export default MentionsLegales;


