import React from 'react';
import {withRouter} from 'react-router-dom';

/*import mixpanel from 'mixpanel-browser';
import {MixpanelProvider, MixpanelConsumer} from 'react-mixpanel';*/

import Routes from './routes';

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"
import {Modal} from "react-bootstrap";


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";

        window.mixpanelhandler = {
            track : function(event, param){


                if(window.mixpanel !== undefined){
                    //mixpanel actif
                    //console.log("mixpanel dispo");

                    if(!this.init){
                        window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {api_host: process.env.REACT_APP_MIXPANEL_PROXY});
                        window.mixpanel.register({
                            "Country" : "France",
                            "Job ID" : "FR02797"
                        });
                    }

                    window.mixpanel.track(event,param);
                }
                else{
                    //mixpanel inactif
                    //console.log("mixpanel pas dispo");
                    //console.log("track",event,param);
                }

                //console.log("track",event,param);
                return true;
            },
            init : false
        }

        console.log(sessionStorage.getItem("gameModal") ?? true)

        this.state = {
            showModal : sessionStorage.getItem("gameModal") ?? 1
        }
    }

    handleHideModal = () => {
        //TODO set view cookie
        sessionStorage.setItem("gameModal", 0)
        this.setState({showModal: '0'})
    }

    render() {

        let route = this.props.location.pathname.split("/")[1]
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route) {
            window.scrollTo(0, 0)
        }

        //envois des event page view
        switch(route){
            case "index" : window.mixpanelhandler.track("Participation_Home loaded",{"Result count" : 1}); break;
            case "jeu" : window.mixpanelhandler.track("\"Let's go\" play button",{"Result count" : 1}); break;
            case "je-participe" : window.mixpanelhandler.track("Participation_Form viewed",{"Result count" : 1}); break;
        }

        this.last_route = route;

        return (
            <>
                {route === 'jeu' && <div className={"mobile_landscape_switcher"}/> }
                <div className="page_wrapper" id={"page_" + route + "_wrapper"}>

                    {route !== 'jeu' && route !== 'attente' && route !== 'operation-terminee' &&
                        <Modal show={this.state.showModal !== '0'} onHide={this.handleHideModal} id={"modalStartGame"} centered={true} backdrop={"static"}>
                            <Modal.Body>
                                <a id={"cta_start"} href={"/jeu"} onClick={() => {
                                    this.handleHideModal()
                                }}>C'est parti !</a>
                                <a id={"cta_start_decouvrir"} href={"#0"} onClick={() => {
                                    window.mixpanelhandler.track("\"Discover offer\"_Selected",{"Result count" : 1})
                                    this.handleHideModal()
                                }}>Découvrir l'offre</a>
                            </Modal.Body>
                        </Modal>
                    }

                    <Header/>
                    <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                        <Routes/>
                    </div>
                    <Footer/>
                    <ScrollTop/>
                </div>
            </>
        );

    }
}

export default withRouter(Layout);
