import React from 'react';
import Contact from "./contact";

const Attente = () => {

  return(
      <>
          <div id="strate1" className={"strate first header_global1"}>
              <div className={"visuel"}/>
              <p>Bonjour,</p>

              <p>Nous vous remercions pour votre visite.<br/>Encore un peu de patience les gourmands…<br className={"d-lg-none"}/> L’opération "Le Goût des Vacances"<br/>de La Fournée Dorée débutera <br className={"d-lg-none"}/>le 27 mai prochain !</p>
              <p>À très vite !</p>
          </div>
      </>
  );
}

export default Attente;
