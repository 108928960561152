import React from 'react';

class ScrollTop extends React.Component {

    constructor(props){

        super(props);

        this.state = {
            visible : false
        }

        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll(){
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        this.setState({ visible : scrollTop > 500?true:false})
    }

    handleClick(){
        window.scrollTo(0,0)
    }

    render(){

        let classes = this.state.visible?"show":"";

        return (
            <a href="#" id="arrowup" className={classes} onClick={this.handleClick}></a>
        );

    }
}

export default ScrollTop;
