import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState} from "react";
import {useSetState} from "react-use";
import {createObject} from "./utils";

import {Modal} from "react-bootstrap";

import {
    SPEED_STEP,
    SPEED_BASE,
    SPAWN_INTERVAL_BASE,
    SPAWN_INTERVAL_MIN,
    SPAWN_INTERVAL_STEP,
    SPEED_MULTIPLICATOR_STEP,
    TRAP_SKIN,
    COIN_SKIN
} from "./constant";

import {Link} from "react-router-dom";

const FallingObject = React.forwardRef((props, ref) => {
    const {x, y, type, index, skin} = props;

    const objectStyle = {
        left: `${x}px`,
        top: `${y}px`
    };

    const hitboxRef = createRef()
    const objectRef = createRef()

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get object() {
            return objectRef.current;
        }
    }));

    return(
        <div className={"falling_object "+type+" "+skin} id={"fo_"+index} style={objectStyle} ref={objectRef} data-value={skin}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const Player = React.forwardRef((props, ref) => {

    const {x} = props;
    const hitboxRef = createRef()
    const playerRef = createRef()

    const playerStyle = {
        left: `${x}%`
    };

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get player() {
            return playerRef.current;
        }
    }));

    useEffect(() => {

        if(props.animation){
            playerRef.current.classList.add("animate")

            playerRef.current.onanimationend = function() {
                props.resetAnimation()
                this.onanimationend = null
                this.classList = ""
            }
        }

    },[props.animation])

    return(
        <div id={"player"} style={playerStyle} ref={playerRef} className={`${props.animation}`} direction={props.direction}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const GameUi = (props) => {

    const {points, lives} = props;

    return(
        <div id={"game_ui"}>
            <div className={"instruction"} id={"game_instruction"}/>
            <div className={"score"}>{points} point{points>1 && "s"}</div>
            <div className={"lives"} data-lives={lives}>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

const CoinCatcher = () => {

    //global state
    const [globalState, setGlobalState] = useSetState({
        bestScore : 0
    })

    //state
    const initialState = {
        objects: [],
        points : 0,
        lives : 3,
        isRunning : false,
        playerPosition : 50,
        playerAnimation : "",
        bgPosition : 0,
        speed : SPEED_BASE,
        spawnTimer : SPAWN_INTERVAL_BASE,
        keyPress : null,
        playerRotation : "right",
        team : "salee",
        skin : {
            good : [],
            trap : []
        },
        decor : []
    }
    const [gameState, setGameState] = useSetState(initialState)
    const [team, setTeam] = useState("")

    //falling object type
    const goodObject = ["basket", "volley", "surf", "racket", "bike"]
    const badObject = ["vaccum", "calcul", "classeur", "computer", "hammer"]

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const requestRef = useRef();
    const gameRef = useRef();
    const playerRef = useRef();
    const gameZoneRef = useRef();

    const toggleFullScreen = (state) => {

        return true;

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }

    }

    //requestFrame
    const advanceStep = useCallback(() => {

        setGameState((oldState) => {

            //move object and detect hitbox
            const newObject = [];
            for(let object of oldState.objects){

                //keep object original speed
                //const newY = object.y + object.speed;

                //use global game speed
                const newY = object.y + oldState.speed;

                //object only exist in state and not in DOM
                //we ignore it
                if(object.ref.current === null) {
                    newObject.push({...object,y: newY});
                    continue
                }

                //objet out of screen -> remove
                if (newY > ( gameRef.current.offsetHeight + object.ref.current.offsetHeight))
                    continue

                //check falling object hitbox
                const objBoundary = object.ref.current.hitbox.getBoundingClientRect();
                const playerBoundary = playerRef.current.hitbox.getBoundingClientRect();

                const hOverlap = (objBoundary.left <= playerBoundary.right && objBoundary.right >= playerBoundary.left)
                const vOverlap = (objBoundary.bottom >= playerBoundary.top && objBoundary.top <= playerBoundary.bottom)

                if( vOverlap && hOverlap ){

                    //get point or lose lives
                    if(object.type === "good"){
                        setPlayerPoint(oldState.points,3)
                    }else{
                        //animate bad
                        handlePlayerLoseLive(oldState.lives)
                    }

                }else{
                    //move object
                    newObject.push({...object,y: newY,});
                }
            }

            //move player
            let playerPosition = oldState.playerPosition

            if(oldState.keyPress === "left" && playerPosition > 0){
                playerPosition -= 1
                document.getElementById("player").classList.remove("right")
                document.getElementById("player").classList.add("left")
            }
            else if(oldState.keyPress === "right" && playerPosition < 100){
                playerPosition += 1
                document.getElementById("player").classList.remove("left")
                document.getElementById("player").classList.add("right")
            }else{
                document.getElementById("player").classList.remove("left")
                document.getElementById("player").classList.remove("right")
            }

            return {
                objects : newObject,
                playerPosition : playerPosition,
                bgPosition : oldState.bgPosition - oldState.speed
            }

        });

        requestRef.current = requestAnimationFrame(advanceStep);

    }, [gameState.objects]);

    //create random falling object
    const spawnFallingObject = () => {
        //choose between coin and trap
        //1/3 -> trap
        //2/3 -> coin
        const rand = Math.floor(Math.random() * 30)
        let type = rand >= 20 ? "trap":"good"

        let skin
        if(type === "good"){
            const randSkin = Math.floor(Math.random() * gameState.skin.good.length)
            skin = gameState.skin.good[randSkin]
        }else{
            const randSkin = Math.floor(Math.random() * gameState.skin.trap.length)
            skin = gameState.skin.trap[randSkin]
        }

        const objRef = createRef()

        setGameState((oldState) => {

            if(oldState.isRunning)
                setTimeout(spawnFallingObject,oldState.spawnTimer)

            return { objects : [...oldState.objects, createObject(type, oldState.speed, objRef, skin)]}
        })

    }

    //return calculate object position
    const getObjectXPosition = (obj) => {

        const Box = gameZoneRef.current.offsetWidth
        //const objBox = obj.ref.current.offsetWidth

        return ( Box * (obj.x / 100) ) - (20 / 2)
    }

    //move player
    const handlePlayerKeyDown = (e) => {
        let key = null

        //save key in state
        switch (e.which) {
            case 37: key = "left"; break;
            case 39: key = "right"; break;
        }

        setGameState({
            keyPress : key,
            playerRotation : key
        })
    }
    const handlePlayerKeyUp = () => {
        setGameState({keyPress : null})
    }

    //lose a live
    const handlePlayerLoseLive = (currentLives) => {
        const newLives = currentLives-1

        setGameState((oldState) => {

            if(newLives <= 0){
                //setShowEndModal(true)
                getPlayerScore(oldState.points, oldState.team)
            }

            return({
                lives : newLives,
                isRunning : newLives > 0,
                playerAnimation : "tilt"
            })

        })

    }

    //getSpeed for current score
    const setPlayerPoint = (currentPoints, points) => {

        const newScore = currentPoints+points
        const speed_multiplicator = Math.floor(newScore/SPEED_MULTIPLICATOR_STEP)
        const speed = SPEED_BASE + (SPEED_STEP * speed_multiplicator)
        let spawnTime = SPAWN_INTERVAL_BASE - ( SPAWN_INTERVAL_STEP * speed_multiplicator )

        if(spawnTime < SPAWN_INTERVAL_MIN)
            spawnTime = SPAWN_INTERVAL_MIN

        setGameState({
            points : newScore,
            speed : speed,
            spawnTimer : spawnTime,
            playerAnimation : "point"
        })

    }

    //game loop
    useEffect(() => {

        const stop = () => {
            requestRef.current && cancelAnimationFrame(requestRef.current);

            toggleFullScreen(false)

            window.mixpanelhandler.track("Game completed",{"Score" : gameState.points})
        }

        if (gameState.isRunning) {
            //intervalRef.current = setInterval(spawnFallingObject, gameState.spawnTimer);
            setTimeout(spawnFallingObject, gameState.spawnTimer);
            requestRef.current = requestAnimationFrame(advanceStep);
        } else {
            stop();
        }

        return () => stop();
    }, [gameState.isRunning])

    //player move event init
    //generate background
    useEffect(() => {

        //bind player event
        document.addEventListener('keydown', handlePlayerKeyDown);
        document.addEventListener('keyup', handlePlayerKeyUp);

        document.querySelectorAll("#mobile_game_control > div").forEach((item) => {
            item.addEventListener('touchstart', function(e){

                handlePlayerKeyDown({which : parseInt(this.dataset.key)})
            })

            item.addEventListener('touchend', function(e){
                console.log("release")
                handlePlayerKeyUp()
            })

        });

        createBackground()

        return ()=> {
            document.removeEventListener('keydown', handlePlayerKeyDown);
            document.removeEventListener('keyup', handlePlayerKeyUp);
        }
    }, []);

    //create background
    const createBackground = () => {

        const decor = ["campagne","montagne","plage"]
        let bg = []
        let prev = -1

        for(let i = 0; i < 50; i++){
            let rand = 0
            //choix du decor aléatoir
            do{
                rand = Math.floor(Math.random() * 3);
            }while(rand === prev)
            prev = rand
            bg = [...bg,decor[rand]]
        }

        setGameState({
            decor : bg
        })
    }

    //start game
    const startGame = () => {

        //setfullScreen
        toggleFullScreen(true)

        //start timer for hiding instruction
        setTimeout(() => document.getElementById("game_instruction").classList.add("hide"), 5000)

        setShowStartModal(false)
        setGameState({
            skin : {
                good : goodObject,
                trap : badObject
            },
            isRunning: true
        })
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("Game Replay_Selected",{"Result count" : 1})

        setGameState(initialState)

        //reset instruction display
        document.getElementById("game_instruction").classList.remove("hide")

        createBackground()

        setShowEndModal(false);
        //setShowStartModal(true);
        startGame()
    }

    //get Score
    const getPlayerScore = (score) => {

        let formData = new FormData();
        formData.append("score", score);

        window.mixpanelhandler.track("Participation completed",{"Result count" : 1})

        fetch(process.env.REACT_APP_API_URL + '/getTopScore.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {

                    setGlobalState({
                        bestScore : json.score,
                    })

                    setShowEndModal(true)
                }

            });

    }


    return(
        <div id={"fullscreenHandler"}>

            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartGame"} centered={true} backdrop={"static"}>
                <Modal.Body>
                   <a id={"cta_start"} href={"#0"} onClick={() => startGame()}>C'est parti !</a>
                   <a id={"cta_start_decouvrir"} href={"/"}>Découvrir l'offre</a>
                </Modal.Body>
            </Modal>
            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndGame"} centered={true} backdrop={"static"} >
                <Modal.Body className={team}>
                    <div className={"playerScore"}>{gameState.points} pts</div>
                    <div className={"bestScore"}>{globalState.bestScore} pts</div>

                    <a href={"#0"} id={"cta_reset"} onClick={resetGame}>Rejouer</a>
                    <Link to={"/"} id={"cta_discover"} onClick={() =>  window.mixpanelhandler.track("Offer button",{"Result count" : 1}) }>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>
            <div id={"game_coin_wrapper"} className={"game_wrapper"} ref={gameRef}>

                <div id={"game_bg"} style={{bottom: gameState.bgPosition}}>
                    {gameState.decor.map((d, index) => {

                        if(index > 0){
                            return (
                                <>
                                    <div className={`raccord ${gameState.decor[index - 1]}-${d}`}/>
                                    <div className={`decor ${d}`}/>
                                </>
                            )
                        } else
                            return (<div className={`decor ${d}`}/>)

                    })}
                </div>

                <GameUi lives={gameState.lives} points={gameState.points} team={gameState.team}/>
                <div id={"mobile_game_control"}>
                    <div data-key={37} className={"left"}/>
                    <div data-key={39} className={"right"}/>
                </div>

                <div id={"game_zone"} ref={gameZoneRef}>
                    {gameState.objects.map((o, index) => {
                        return( <FallingObject x={getObjectXPosition(o)} y={o.y} index={index} type={o.type} ref={o.ref} skin={o.skin}/> )
                    })}
                    <Player x={gameState.playerPosition} animation={gameState.playerAnimation} resetAnimation={ () => setGameState({playerAnimation : ""}) } direction={gameState.playerRotation} ref={playerRef}/>
                </div>

            </div>
        </div>
    )
}

export default CoinCatcher