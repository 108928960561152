import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import seo_data from '../../file/seo.json';
/*
	Permet d'ajouter des contrainte et de manipuler les routes
*/

const RouteWrapper = ({component : Component,...rest}) => {

	let seo
	
	if(seo_data[rest.path] !== undefined)
		seo = seo_data[rest.path]
	else
		seo = seo_data['default']

	let title = seo.title
	let description = seo.description
	let keywords = seo.keyword

	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keyword" content={keywords} />
			</Helmet>

			<Route {...rest} component={Component} />
		</React.Fragment>
	); 
}

export default RouteWrapper;