import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import MediaQuery from "react-responsive/src/Component";

import {Form, Modal, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';
import CustomComboBox from "../form/CustomComboBox";
import BirthDayField from '../form/BirthDayField';

//import {MixpanelConsumer} from 'react-mixpanel';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import Contact from "./contact";
import Routes from "../routes";
import {numberPattern} from "../form/form_pattern_helper.jsx";

const ListeProduit = props => {

    const NomProduit = {
        "": [],
        "Brioches": [
            [
                "6 Brioches Parisiennes Pur Beurre",
                "6 Brioches Parisiennes Pur Beurre"],
            [
                "Brioche Tranchée Crème Fraîche",
                "Brioche Tranchée Crème Fraîche"],
            [
                "Brioche Tranchée Marbrée Chocolat",
                "Brioche Tranchée Marbrée Chocolat"],
            [
                "Brioche Tranchée Pépites",
                "Brioche Tranchée Pépites"],
            [
                "Brioche Tressée Tranchée",
                "Brioche Tressée Tranchée"],
            [
                "Brioche Tressée Sucre",
                "Brioche Tressée Sucre"],
            [
                "Brioche Tranchée Vegan",
                "Brioche Tranchée Vegan"
            ]
        ],
        "Chinois": [
            [
                "Chinois Crème Pâtissière",
                "Chinois Crème Pâtissière"],
            [
                "Chinois Crème Pâtissière et Pépites de Chocolat",
                "Chinois Crème Pâtissière et Pépites de Chocolat"]
        ],
        "Croissants": [
            [
                "8 Croissants",
                "8 Croissants"],
            [
                "10 Minis Croissants",
                "10 Minis Croissants"]
        ],
        "Gâches": [
            [
                "Gâche Tranchée Bio",
                "Gâche Tranchée Bio"],
            [
                "Gâche Tranchée Beurre Frais",
                "Gâche Tranchée Beurre Frais"],
            [
                "Gâche Tranchée Sucre",
                "Gâche Tranchée Sucre"],
            [
                "Gâche Tranchée Pépites",
                "Gâche Tranchée Pépites"],
            [
                "Gâche des Gourmands",
                "Gâche des Gourmands"],
            [
                "Gâche Ovale au Beurre Frais",
                "Gâche Ovale au Beurre Frais"]
        ],
        "Navettes": [
            [
                "8 Navettes Beurre Frais",
                "8 Navettes Beurre Frais"],
            [
                "8 Navettes Pépites de Chocolat",
                "8 Navettes Pépites de Chocolat"]
        ],
        "Pains au Chocolat": [
            [
                "8 Pains au Chocolat",
                "8 Pains au Chocolat"],
            [
                "10 Minis Pains au Chocolat",
                "10 Minis Pains au Chocolat"]
        ],
        "Pains au Lait": [
            [
                "10 Pains au Lait",
                "10 Pains au Lait"],
            [
                "8 Petits Pains Vegan",
                "8 Petits Pains Vegan"]
        ],
        "Pancakes": [
            [
                "8 Pancakes Tout Chocolat",
                "8 Pancakes Tout Chocolat"],
            [
                "10 Pancakes Crème Fraîche",
                "10 Pancakes Crème Fraîche"]
        ],
        "Petits Chinois": [
            [
                "6 Petits Chinois Crème Pâtissière",
                "6 Petits Chinois Crème Pâtissière"],
            [
                "6 Petits Chinois Pépites de Chocolat",
                "6 Petits Chinois Pépites de Chocolat"]
        ]
    };

    const label = {
        "1" : "1<sup>er</sup>",
        "2" : "2<sup>ème</sup>",
    }

    const [categorieProduit, setCategorieProduit] = useState("");
    const [nomsProduit, setNomsProduit] = useState(NomProduit[categorieProduit]);

    useEffect(() => {
        setNomsProduit(NomProduit[categorieProduit]);
        const combo = document.getElementById(`nom_produit${props.id}`);
        combo.selectedIndex = 0;

    }, [categorieProduit]);

    return (
        <>
            <CustomComboBox name={`categorie_produit${props.id}`} id={`categorie_produit${props.id}`}
                            label={`Catégorie du ${label[props.id]} produit porteur de l'offre acheté :`}
                            placeholder={"Sélectionner"}
                            options={[
                                [
                                    "Brioches",
                                    "Brioches"],
                                [
                                    "Chinois",
                                    "Chinois"],
                                [
                                    "Croissants",
                                    "Croissants"],
                                [
                                    "Gâches",
                                    "Gâches"],
                                [
                                    "Navettes",
                                    "Navettes"],
                                [
                                    "Pains au Chocolat",
                                    "Pains au Chocolat"],
                                [
                                    "Pains au Lait",
                                    "Pains au Lait"],
                                [
                                    "Pancakes",
                                    "Pancakes"],
                                [
                                    "Petits Chinois",
                                    "Petits Chinois"
                                ]
                            ]}
                            register={props.register}
                            onChange={(e) => {
                                setCategorieProduit(e.target.value);
                            }}/>

            <CustomComboBox name={`nom_produit${props.id}`} id={`nom_produit${props.id}`}
                            label={`Nom du ${label[props.id]} produit porteur de l'offre acheté :`}
                            placeholder={"Sélectionner"}
                            options={nomsProduit}
                            register={props.register}/>
        </>
    );
};

const JeParticipeForm = (props) => {

    const {register, handleSubmit, errors, clearError, setError, setValue} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);

    //form validation
    const onSubmit = data => {

        //console.log(props)

        submitRef.current.setAttribute("disabled", "disabled");

        //mixpanel
        props.mixpanel.track("Participation Form Submitted",{
            "Result count" : 1,
            "Civility / Gender" : data.civilite/*,
            "Date of Birth" : data.jour_naissance+"/"+data.mois_naissance+"/"+data.annee_naissance*/
        });

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_form.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setShowMerci(true);
                } else {
                    //setFormError(json.error);
                    alert(json.error);

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

            <div className={"form_part"}>
                <CustomFieldText name="code_unique1" label="Code unique 1*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.code_unique}
                })} error={errors.code_unique1 && errors.code_unique1.message}/>
                <CustomFieldText name="code_unique2" label="Code unique 2*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.code_unique2}
                })} error={errors.code_unique2 && errors.code_unique2.message}/>

                <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Sélectionner"}
                                options={[
                                    [
                                        "Madame",
                                        "Madame"
                                    ],
                                    [
                                        "Monsieur",
                                        "Monsieur"
                                    ],
                                    [
                                        "Ne souhaite pas se prononcer",
                                        "Ne souhaite pas se prononcer"
                                    ]
                                ]}
                                register={register({
                                    required: {
                                        value: true,
                                        message: Helper.EmptyErrorMessages.civilite
                                    }
                                })}
                                error={errors.civilite && errors.civilite.message}/>

                <CustomFieldText name="prenom" label="Prénom*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.prenom},
                    pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom},
                    minLength: {value: 2, message: Helper.InvalidErrorMessages.prenom}
                })} error={errors.prenom && errors.prenom.message}/>
                <CustomFieldText name="nom" label="Nom de famille*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.nom},
                    pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom},
                    minLength: {value: 2, message: Helper.InvalidErrorMessages.nom}
                })} error={errors.nom && errors.nom.message}/>

                <CustomFieldText name="mail" label="Adresse e-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern: Pattern.emailPattern,
                                         validate: {
                                             domain: (value) => Helper.checkMailDomain(value)
                                             /*confirm : checkMailConfirm*/
                                         }
                                     })}
                                 error={[
                                     errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>

                <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                 register={
                                     register({
                                         required: true,
                                         pattern: Pattern.emailPattern,
                                         validate: {
                                             domain: (value) => Helper.checkMailDomain(value),
                                             confirm: Helper.checkMailConfirm
                                         }
                                     })}
                                 error={[
                                     errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                     errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                     errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                 ]}/>
                <BirthDayField
                    label={"Date de naissance<span>*</span><div>(format JJ/MM/AAAA)</div>"}
                    register={
                        register({
                            required: true,
                            validate: Helper.checkBirthDate
                        })}
                    error={[
                        (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                        (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                        (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance),
                        (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                        (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                        (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)

                    ]}/>

            </div>

            <div className={"form_part"}>
                <CustomFieldText name="adresse" label="Adresse postale*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.adresse},
                    pattern: {
                        value: Pattern.numberAndAlphaPattern,
                        message: Helper.InvalidErrorMessages.adresse
                    }
                })} error={errors.adresse && errors.adresse.message}/>
                <CustomFieldText name="cp" label="Code postal*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.cp},
                    pattern: {
                        value: Pattern.numberPattern,
                        message: Helper.InvalidErrorMessages.cp
                    },
                    minLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                    maxLength: {value: 5, message: Helper.InvalidErrorMessages.cp}
                })} error={errors.cp && errors.cp.message}/>
                <CustomFieldText name="ville" label="Ville*" register={register({
                    required: {value: true, message: Helper.EmptyErrorMessages.ville},
                    pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.ville},
                    minLength: {value: 2, message: Helper.InvalidErrorMessages.ville}
                })} error={errors.ville && errors.ville.message}/>

                <ListeProduit register={register()} id={1}/>
                <ListeProduit register={register()} id={2}/>
            </div>

            <div className={"optin_container"}>

                <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                    <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1"
                           ref={register({required: true})}/>

                    <label htmlFor="optin_reglement">
                        Je reconnais avoir pris connaissance du <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du Jeu</a> de l’opération et en accepter les termes et conditions.<span>*</span>
                    </label>
                    <div className="error text-center">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
                </div>

                <div id={"optin_consent_wrapper"} className="checkbox_wrapper form_row_wrapper">
                    <input type="checkbox" name="optin_consent" id="optin_consent" value="1" ref={register({required: true})}/>

                    <label htmlFor="optin_consent">En participant à cette offre, et si ma participation est valide, je comprends que je serai automatiquement inscrit au tirage au sort pour tenter de gagner un séjour sur-mesure pour 4 personnes.<span>*</span></label>
                    <div className="error text-center">{errors.optin_consent && Helper.EmptyErrorMessages.optin_consent}</div>
                </div>

                <div className={"mandatory"}>
                    *Tous les champs marqués d’un astérisque (*) sont obligatoires. Toute demande incomplète ne pourra être validée et traitée.
                </div>
            </div>
            <div id={"rgpd_text_wrapper"}>

                <div id={"optin_partenaire_wrapper"} className="checkbox_wrapper form_row_wrapper">
                    <input type="checkbox" name="optin_partenaire" id="optin_partenaire" value="1" ref={register()}/>

                    <label htmlFor="optin_partenaire">
                        Oui, je souhaite recevoir les newsletters mensuelles de La Fournée Dorée par e-mail.
                    </label>
                </div>
                <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                    <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>

                    <label htmlFor="optin_tlc">
                        Oui, je souhaite être contacté par TLC Worldwide à des fins d'enquêtes de satisfaction, d'information sur nos offres promotionnelles et d'études de marché par e-mail.
                    </label>
                </div>

                <p>
                    Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, les informations recueillies sont destinées à TLC Marketing en sa qualité de responsable de traitement.
                </p>

                <p>
                    TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Vos informations seront conservées jusqu’au 31/01/2028, au plus tard conformément à la réglementation si vous avez coché une case opt-in du formulaire de participation. Autrement, vos données seront supprimées à partir du 30/04/2025. Vous bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant les données personnelles vous concernant. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser votre demande auprès de TLC Marketing France par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> ou par courrier à l’adresse suivante : TLC Marketing France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.
                </p>
            </div>

            {/* recaptcha */}
            <div className="text-center" id={"recaptcha_wrapper"}>
                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
            </div>

            {/* server error */}
            <div className="text-center">
                <div className={"error text-center mb-3"} id={"global_error"}>
                    {/*error*/}
                </div>
                <button className={"cta"} id={"submit_cta"} ref={submitRef}>Valider</button>
            </div>


        </Form>
    );
};

const JeParticipeMerci = () => {

    return (
        <>
            <div id={"bravoStrate1"} className={"strate header_global1 first "}>

                <h2>
                    Merci !
                    <span>Votre participation <br/>a bien été prise en compte.</span>
                </h2>

                <p>
                    Si elle est valide, vous recevrez sous 2 <br className={"d-lg-none"}/>
                    semaines maximum un e-mail <br className={"d-lg-block d-none"}/>avec <br className={"d-lg-none"}/>
                    votre code cadeau et les instructions <br className={"d-lg-none"}/>
                    nécessaires à son utilisation. <br className={"d-lg-block d-none"}/>Vous serez <br className={"d-lg-none"}/>
                    également automatiquement inscrit <br className={"d-lg-none"}/>
                    au tirage au sort pour tenter de <br className={"d-lg-none"}/>
                    gagner <br className={"d-lg-block d-none"}/>un séjour sur-mesure <br className={"d-lg-none"}/>
                    d'une valeur maximale de 3 000€.
                </p>
                <p>
                    Dans le cas contraire, si elle n’est <br className={"d-lg-none"}/>
                    pas valide, vous recevrez un e-mail <br/>
                    vous en expliquant la raison.
                    <br/><br/>
                    Si vous ne recevez pas cet e-mail, n’oubliez pas de regarder <br className={"d-lg-none"}/>
                    dans vos courriers indésirables ou spams.
                </p>

                <p>
                    En attendant, jouez au Défi des Vacances et tentez de récupérer un maximum d’accessoires.
                </p>
                <Link to={"/jeu"} className={"cta"} onClick={() => window.mixpanelhandler.track("Game Play_Confirmation Page",{"Result count" : 1})}>C'est parti !</Link>

                <div className={"visuel"}/>
            </div>

            <Contact/>
        </>
    );
};

const JeParticipe = () => {


    const [showMerci, setShowMerci] = useState(false);

    return (
        <>
            {!showMerci &&
                <div id={"participeStrate1"} className={"strate header_global1 first last"}>
                    <h1>Je participe</h1>
                    <p>Complétez le formulaire ci-dessous pour <br className={"d-lg-none"}/>
                        bénéficier de <br className={"d-lg-block d-none"}/>vos deux activités offertes et <br className={"d-lg-none"}/>
                        tentez votre chance pour <br className={"d-lg-block d-none"}/>gagner un séjour <br className={"d-lg-none"}/>
                        sur-mesure en famille !
                    </p>
                    <JeParticipeForm setShowMerci={setShowMerci} mixpanel={window.mixpanelhandler}/>
                </div>
            }

            { showMerci && <JeParticipeMerci/> }

        </>
    );
};

export default JeParticipe;
